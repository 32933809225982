import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { MOBILE_WIDTH } from 'src/helpers/constants';
import { Events } from 'src/helpers/events';
import { isPlatformBrowser } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet, CommonModule],
  providers: [Events],
})
export class AppComponent implements OnInit {

  private isBrowser: boolean = false;

  constructor(private events: Events, @Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2,
  private titleService: Title, private metaService: Meta) {
    // this.checkScreenSize();
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event) {
  //   this.checkScreenSize();
  // }

  ngOnInit(): void {
    if (this.isBrowser) {
      this.renderer.listen('window', 'resize', (event) => {
        this.checkScreenSize();
      });

      this.checkScreenSize();
    }

    this.titleService.setTitle('AcusticApp® | Reduce tu ruido');
    
    this.metaService.updateTag({
      name: 'description',
      content:
        'Con AcusticApp® descubre cómo el vidrio puedo ayudarte a reducir los ruidos externos.',
    });
    this.metaService.updateTag({ name: 'keywords', content: 'reduceturuido, AcusticApp®, vitro, vidrio, sonido, reducir, acustica, acoustica app, acustic app, reduce tu ruido, ruido, vidrios, ventanas, sonido, acustic, acoustic, vitro app' });
  
    this.metaService.updateTag({ property: 'og:title', content: 'AcusticApp® | Reduce tu ruido' });
    this.metaService.updateTag({
      property: 'og:description',
      content:
        'Con AcusticApp® descubre cómo el vidrio puedo ayudarte a reducir los ruidos externos.',
    });
    this.metaService.updateTag({ property: 'og:image', content: 'https://www.reduceturuido.com/assets/logo_vitro.png' });
    this.metaService.updateTag({ property: 'og:url', content: 'https://www.reduceturuido.com' });
    this.metaService.updateTag({ property: 'og:type', content: 'website' });
    this.metaService.updateTag({ property: 'og:site_name', content: 'reduceturuido' });
  }

  checkScreenSize() {
    // const isMobileVersion = window.innerWidth < MOBILE_WIDTH;
    // this.events.windowSize.next(isMobileVersion);
    if (this.isBrowser) {
      const isMobileVersion = window.innerWidth < MOBILE_WIDTH;
      // Aquí debes reemplazar `this.events.windowSize.next(isMobileVersion);` con tu lógica
      this.events.windowSize.next(isMobileVersion);
      console.log('Is mobile version:', isMobileVersion);
    }
  }
}
